import React from "react"
import Layout from "../layout/pt"
import { graphql } from 'gatsby'
import SEO from "../components/SEO"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import Video from "../components/Video"
import Introduction from "../components/Introduction"
import Feature from "../components/FeatureLine"
import FeatureFull from "../components/FeatureLine/full"
import Carousel from "../components/Carousel/new"
import SlidingText from "../components/SlidingText"
import Draggable from "../components/Draggable"
import VideoDetail from "../components/VideoDetail"
import MainSite from "../components/CTA-MainSite"
import FormContact from "../components/FormContact"

import VideoMobile from "../components/Video/mobile"
import FeatureImageMobile from "../components/FeatureLine/image"
import FeatureMobile from "../components/FeatureLine/mobile"
import FeatureSliderMobile from "../components/FeatureSliderMobile"

// ASSETS
import BannerVideo from "../assets/videos/banner.mp4"
import BannerVideoMobile from '../assets/videos/banner-mobile.mp4'
import DetailVideo from "../assets/videos/single.mp4"
import DetailVideoMobile from "../assets/videos/single-mobile.mp4"

const IndexPage = ({ location, data }) => {
  const breakpoints = useBreakpoint();

  const infoIntro = data.allLandingJson.edges[0].node.pt.intro
  const infoVideo = data.allLandingJson.edges[0].node.pt.video_intro
  const infoFeature1 = data.allLandingJson.edges[0].node.pt.feature_1
  const infoFeature1Mobile = data.allLandingJson.edges[0].node.pt.feature_1_mobile
  const infoFeature2Mobile = data.allLandingJson.edges[0].node.pt.feature_2_mobile
  const infoFeature2 = data.allLandingJson.edges[0].node.pt.feature_2
  const infoCarousel = data.allLandingJson.edges[0].node.pt.slider
  const infoCarouselMobile = data.allLandingJson.edges[0].node.pt.slider_mobile
  const infoSlidingText = data.allLandingJson.edges[0].node.pt.sliding_text
  const infoDraggable = data.allLandingJson.edges[0].node.pt.sliding_image
  const infoFeature3 = data.allLandingJson.edges[0].node.pt.feature_3
  const infoMainSite = data.allLandingJson.edges[0].node.pt.main_site
  const infoForm = data.allFormJson.edges[0].node.pt

  return (
  <Layout location={location}>
    <SEO title="Ramalhos - Pure Baking Pleasure" titleTemplate="%s"/>

    {/* DESKTOP */}
    {!breakpoints.as &&
      <>
        <Video id="banner-video" info={infoVideo} toClick="#introduction" src={BannerVideo} className="full-width full-height"/>
        <Introduction id="introduction" info={infoIntro}/>
        <Feature id="feature-1" info={infoFeature1}/>
        <FeatureFull id="feature-2" info={infoFeature2}/>
        <Carousel id="carousel" info={infoCarousel}/>
        <SlidingText id="sliding-text" info={infoSlidingText}/>
        <Draggable id="sliding-image" info={infoDraggable}/>
        <VideoDetail id="feature-3"  info={infoFeature3} src={DetailVideo} className="full-width full-height"/>
      </>
    }

    {/* MOBILE */}
    {breakpoints.as &&
      <>
        <VideoMobile id="banner-video-mobile" info={infoVideo} toClick="#introduction" src={BannerVideoMobile} className="full-width full-height"/>
        <Introduction id="introduction" info={infoIntro}/>
        <FeatureImageMobile id="feature-image-mobile" info={infoFeature1Mobile} whiteBg/>
        <FeatureMobile id="feature-1-mobile" info={infoFeature1} noLineBreak/>
        <FeatureImageMobile id="feature-image-mobile2" info={infoFeature2Mobile} fullHeight/>
        <FeatureMobile id="feature-2-mobile" info={infoFeature2} line/>
        <FeatureSliderMobile id='feature-slider-mobile' info={infoCarouselMobile}/>
        <SlidingText  id="sliding-text" info={infoSlidingText}/>
        <Draggable id="sliding-image" info={infoDraggable}/>
        <VideoDetail id="feature-3"  info={infoFeature3} src={DetailVideoMobile} className="full-width full-height"/>
        <FeatureMobile id="feature-3-mobile" info={infoFeature3} single/>
      </>
    }
    <MainSite id="main-site" info={infoMainSite}/>
    <FormContact  id="form-contact"   info={infoForm} lang="pt"/>
  </Layout>
  )
}

export default IndexPage

export const QueryLanding = graphql`
  query {
    allLandingJson {
      edges {
        node {
          pt {
            video_intro {
              overtitle
              title
              loading {
                name
                publicURL
              }
            }
            intro {
              title
              oven_title
              oven_subtitle
              image {
                name
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            feature_1 {
              question
              title
              text
              text_mobile
              image {
                name
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            feature_2 {
              text
              text_mobile
              image {
                name
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            feature_1_mobile {
              image {
                name
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            feature_2_mobile {
              image {
                name
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            slider {
              image {
                name
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            slider_mobile {
              image {
                name
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            sliding_text {
              text_1_1
              text_1_2
              text_1_3
              text_2_1
              text_2_2
            }
            sliding_image {
              image {
                name
                childImageSharp {
                  fluid(maxWidth: 2500, quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              help_text
            }
            feature_3 {
              title_1
              title_2
              text
            }
            main_site {
              title
              link
            }
          }
        }
      }
    }
    allFormJson {
      edges {
        node {
          pt {
            field1
            field2
            field3
            field4
            field5
            field6
            field7
            field8
            field9
            field10
            button1
            button2
            button3
            dropdown1
            dropdown2
            dropdown3
            dropdown4
            text1
            text2
            submit
            call
            confirmation1
            confirmation2
            other
          }
        }
      }
    }
  }
`